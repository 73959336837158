@import "@public/styles/mixins.scss";
@import "@public/styles/variables.scss";

.info {
  background-color: $colorSecondary;
  padding: 100px 0;

  @include deviceWidth(tabletLandscape) {
    padding: 70px 0;
  }
  @include deviceWidth(tabletPortrait) {
    padding: 70px 0;
  }
  @include deviceWidth(mobile) {
    padding: 60px 0 40px;
  }

  &__inner {
    @include w-layout;
  }

  &__title {
    @include m-typography(64px, 64px, 700);
    margin-bottom: 72px;
    color: $colorPrimary;

    @include deviceWidth(tabletLandscape) {
      margin-bottom: 40px;
    }
    @include deviceWidth(tabletPortrait) {
      @include m-typography(36px, 36px, 700);
      margin-bottom: 40px;
    }
    @include deviceWidth(mobile) {
      @include m-typography(24px, 24px, 700);
      margin-bottom: 40px;
    }
  }

  &__products, &__about {
    margin-bottom: 72px;
  }

  &__about {
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include deviceWidth(mobile) {
        flex-direction: column;
      }
    }

    &__description {
      @include m-typography(20px, 28px, 500);
      width: 50%;
      color: #343434;
      margin-right: 25px;

      @include deviceWidth(tabletPortrait) {
        @include m-typography(14px, 19px, 500);
        margin-right: 21px;
      }
      @include deviceWidth(mobile) {
        @include m-typography(14px, 19px, 500);
        width: 100%;
        margin: 0 0 15px;
      }
    }

    &__image {
      width: 50%;

      @include deviceWidth(mobile) {
        width: 100%;
      }

      img {
        border-radius: 15px;
      }
    }
  }

  &__edge {
    &__inner {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      @include deviceWidth(tabletPortrait) {
        flex-direction: column;
      }
      @include deviceWidth(mobile) {
        flex-direction: column;
      }
    }

    &__fake {
      width: 50%;
    }

    &__image {
      position: absolute;
      max-width: 660px;
      max-height: 525px;
      left: 0;

      @include deviceWidth(tabletLandscape) {
        width: calc(60% - 70px);
      }
      @include deviceWidth(tabletPortrait) {
        position: unset;
        margin-bottom: 20px;
        max-width: 100%;
        max-height: 100%;
      }
      @include deviceWidth(mobile) {
        position: unset;
        margin-bottom: 20px;
        max-width: 100%;
        max-height: 100%;
      }

      img {
        border-radius: 15px;
      }

      @include deviceWidth(tabletPortrait) {
        img {
          border-radius: 10px;
        }
      }
      @include deviceWidth(mobile) {
        img {
          border-radius: 10px;
        }
      }
    }

    &__description {
      padding: 38px 60px;
      border-radius: 15px;
      width: 50%;
      background-color: $colorPrimary;

      @include deviceWidth(tabletLandscape) {
        padding: 30px 60px;
      }
      @include deviceWidth(tabletPortrait) {
        @include m-typography(36px, 36px, 700);
        padding: 30px 20px 30px 60px;
        width: 100%;
      }
      @include deviceWidth(mobile) {
        @include m-typography(24px, 24px, 700);
        padding: 20px 15px;
        width: 100%;
      }

      &__title {
        @include m-typography(20px, 140%, 700);
        color: $colorOnPrimary;
        margin-bottom: 20px;

        @include deviceWidth(tabletLandscape) {
          @include m-typography(18px, 25px, 700);
          margin-bottom: 10px;
        }
        @include deviceWidth(tabletPortrait) {
          @include m-typography(18px, 25px, 700);
          margin-bottom: 10px;
        }
        @include deviceWidth(mobile) {
          @include m-typography(18px, 25px, 700);
          margin-bottom: 10px;
        }
      }

      &__subTitle {
        @include m-typography(16px, 22px, 300);
        color: $colorOnPrimary;

        @include deviceWidth(tabletLandscape) {
          @include m-typography(14px, 20px, 300);
        }
        @include deviceWidth(tabletPortrait) {
          @include m-typography(14px, 20px, 300);
        }
        @include deviceWidth(mobile) {
          @include m-typography(14px, 20px, 300);
        }

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 72px;

    @include deviceWidth(tabletPortrait) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px 20px;
    }
    @include deviceWidth(mobile) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 20px 20px;
      margin-bottom: 60px;
    }
  }

  &__card {
    position: relative;
    padding: 25px 30px;
    border-radius: 24px;

    &Link {
      @include m-hover;
    }

    @include deviceWidth(tabletLandscape) {
      padding: 15px;
    }
    @include deviceWidth(tabletPortrait) {
      padding: 15px;
    }
    @include deviceWidth(mobile) {
      padding: 15px;
    }

    &__title {
      @include m-typography(24px, 28px, 700);
      color: $colorOnPrimary;
      margin-bottom: 9px;
      position: relative;
      z-index: 10;

      @include deviceWidth(tabletLandscape) {
        @include m-typography(15px, 17px, 700);
      }
      @include deviceWidth(tabletPortrait) {
        @include m-typography(15px, 17px, 700);
      }
      @include deviceWidth(mobile) {
        @include m-typography(15px, 17px, 700);
      }
    }

    &__subTitle {
      @include m-typography(16px, 140%, 500);
      color: $colorOnPrimary;
      position: relative;
      z-index: 10;

      @include deviceWidth(tabletLandscape) {
        @include m-typography(11px, 15px, 500);
      }
      @include deviceWidth(tabletPortrait) {
        @include m-typography(11px, 15px, 500);
      }
      @include deviceWidth(mobile) {
        @include m-typography(11px, 15px, 500);
      }

      &Link {
        @include m-hover;
        text-decoration: underline;
      }

      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      background-color: rgba($colorPrimary, .7);
      transition: 250ms background-color;
    }

    &Home {
      width: auto;
      height: auto;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-image: url("../../../../../../assets/slider/slide-1.webp");
    }

    &Furniture {
      width: auto;
      height: auto;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-image: url("../../../../../../assets/slider/slide-5.webp");
    }

    &PlyWood {
      width: auto;
      height: auto;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-image: url("../../../../../../assets/slider/slide-3.webp");
    }

    &Service {
      width: auto;
      height: auto;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-image: url("../../../../../../assets/slider/slide-6.webp");

      @include deviceWidth(tabletLandscape) {
        background-position-y: bottom;
      }
      @include deviceWidth(tabletPortrait) {
        background-position-y: bottom;
      }
    }
  }
}
